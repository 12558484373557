<template>
  <div>
    <div v-if="planner_view">
      <CCard>
        <CTabs :activeTab.sync="activeTab">
          <CTab :title="$t('role.planning.market.tab')">
            <PlanningMarket v-if="activeTab == 0" />
          </CTab>
          <CTab :title="$t('role.planning.wh.tab')">
            <PlanningWh v-if="activeTab == 1" />
          </CTab>
          <CTab :title="$t('role.planning.producer.tab')">
            <PlanningProducer v-if="activeTab == 2" />
          </CTab>
          <CTab :title="$t('role.planning.island.tab')">
            <PlanningIsland v-if="activeTab == 3" />
          </CTab>
          <!-- <CTab :title="Mezzi">
          <PlanningTruck v-if="activeTab == 4" />
        </CTab> -->
          <CTab :title="$t('role.planning.closing.tab')">
            <PlanningClosing v-if="activeTab == 4" />
          </CTab>
        </CTabs>
      </CCard>
      <CCard>
        <CCardBody>
          <Planningcalendar />
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <CCard>
        <CTabs :activeTab.sync="activeTab">
          <CTab :title="$t('role.planning.calendar.tab')">
            <Planningcalendar v-if="activeTab == 0" />
          </CTab>
          <CTab :title="$t('role.planning.closures.tab')">
            <PlanningClosing v-if="activeTab == 1" />
          </CTab>
        </CTabs>
      </CCard>
    </div>
  </div>
</template>

<script>
// import { CircleStencil, Cropper } from "vue-advanced-cropper";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { GetDataManager, ApiCall } from "../../../ds/index";
import DrfAdaptor from "../../../http/EJ2_adapter";
import { ROLE_TYPE } from "../../../helpers/acl";
import PlanningProducer from "./PlanningProducer";
import PlanningIsland from "./PlanningIsland";
import PlanningMarket from "./PlanningMarket";
import PlanningWh from "./PlanningWh";
import PlanningTruck from "./PlanningTruck";
import PlanningClosing from "./PlanningClosing";
import Planningcalendar from "./PlanningCalendar";
import { apiUrl } from "../../../http";
// import ProfileLogo from "./ProfileLogo";
// import ProfileImages from "./ProfileImages";

export default {
  name: "ProfileData",
  data() {
    return {
      activeTab: 0,
      wallet: null,
      role: null,
    };
  },
  mounted() {
    // this.getFamily();
    this.getWallet();
    // this.getProducer();

    let self = this;
    // Update propduct Info
    ApiCall(
      GetDataManager("auth_frole", [], this.$store.state.role.id),
      new Query(),
      function (e) {
        // self.pp = e.result;
        self.role = e.result;
      }
    );
  },
  computed: {
    planner_view() {
      if (
        [ROLE_TYPE.MARKET, ROLE_TYPE.SHOP].indexOf(
          this.$store.state.role.r_type
        ) > -1
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // getFamily() {
    //   const dm = new DataManager({
    //     url:
    //       LOONITY_BASE_API_URL +
    //       "/api/auth/roles/" +
    //       this.$store.state.role.id +
    //       "/family/" +
    //       this.$store.state.role.family_id,
    //     adaptor: new DrfAdaptor(),
    //     crossDomain: true,
    //   });
    //   dm.executeQuery(new Query()).then((e) => {
    //     // this.family = e.result;
    //     let role = this.role;
    //     role.family = e.result;
    //     console.log(role);
    //     this.$store.commit("setRole", role);
    //   });
    // },
    getWallet() {
      const dm = new DataManager({
        url: apiUrl(
          `/api/auth/family/${this.$store.state.role.family_id}/wallet/`
        ),
        adaptor: new DrfAdaptor(),
        crossDomain: true,
      });
      dm.executeQuery(new Query()).then((e) => {
        this.wallet = e.result;
      });
    },
    // getProducer() {
    //   const dm = new DataManager({
    //     url:
    //       LOONITY_BASE_API_URL +
    //       "/api/auth/roles/" +
    //       this.$store.state.role.id +
    //       "/producer/" +
    //       this.$store.state.role.id,
    //     adaptor: new DrfAdaptor(),
    //     crossDomain: true,
    //   });
    //   dm.executeQuery(new Query()).then((e) => {
    //     this.producer = e.result;
    //     let role = this.role;
    //     role.producer = e.result;
    //     this.$store.commit("setRole", role);
    //   });
    // },
  },
  components: {
    PlanningProducer,
    PlanningIsland,
    Planningcalendar,
    PlanningMarket,
    PlanningWh,
    PlanningTruck,
    PlanningClosing,
    // RoleImageRect,
    // RoleImageSquare,
    // RoleData,
    // RoleDataFamily,
    // RoleDataProducer,
    // RoleImageFamily,
    // ProfileImages,
    // ProfileInfo,
    // ProfileLogo,
  },
};
</script>
