<template>
  <div>
    <div class="card-title mb-3">
      {{ $t("role.planning.island.title") }}
    </div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="island.role.name"
            :headerText="$t('models.mpi.island')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            :allowEditing="true"
            editType="dropdownedit"
            :edit="islandParams"
          ></e-column>
          <e-column
            field="price"
            :headerText="$t('models.mpi.price')"
            type="number"
            clipMode="EllipsisWithTooltip"
            defaultValue="0"
          ></e-column>
          <e-column
            field="offset"
            :headerText="$t('models.mpi.offset')"
            type="number"
            clipMode="EllipsisWithTooltip"
            defaultValue="0"
          ></e-column>
          <e-column
            field="island.role.country"
            :headerText="$t('models.logistics.connections.country')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="island.role.tel"
            :headerText="$t('models.logistics.connections.phone')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="island.role.url"
            headerText="Url"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="island.role.info_contact"
            :headerText="this.$t('models.logistics.connections.contacts')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="island.role.desc_zone"
            :headerText="$t('models.logistics.connections.serviceZone')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="member_from"
            :headerText="$t('models.logistics.connections.activeSince')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import { GetDataManager, GetDataManagerNew } from "../../../ds/index";
import TB from "../../../common/TB";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { Query } from "@syncfusion/ej2-data";
let stateElem, stateObj;
export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_marketisland_connections", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: [
            // "user.first_name",
            // "user.last_name",
            "island.role.name",
            "id",
          ],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Normal",
        },
        toolbar: ["Add", "Edit", "Delete", "Update", "Cancel"],
        // reload_on_save:true,
      },
      islandParams: {
        create: () => {
          stateElem = document.createElement("input");
          return stateElem;
        },
        read: () => {
          if (stateObj) {
            return stateObj.value;
          }
          return stateElem.getAttribute("island_id");
        },
        destroy: () => {
          if (stateObj) {
            stateObj.destroy();
          }
        },
        write: (args) => {
          if (args.rowData.island.id > 0) {
            stateElem.value = args.rowData.island.role.name;
            stateElem.setAttribute("island_id", args.rowData.island.id);
          } else {
            stateObj = new DropDownList({
              dataSource: GetDataManagerNew("role_marketisland", [
                this.$store.state.role.id,
              ]),
              fields: { value: "id", text: "role.name" },
              value: args.rowData.island.id,
              enabled: !(args.rowData.island.id > 0),
              query: new Query().take(10),
              // .where("country_id", "equal", args.rowData.address.country)
              // .take(10),
              placeholder: "Seleziona un'isola",
              floatLabelType: "Never",
              allowFiltering: true,
              actionComplete: () => false,
            });
            stateObj.appendTo(stateElem);
          }
        },
      },
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>
