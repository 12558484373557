<template>
  <div>
    <div class="card-title mb-3">
      {{ $t("role.planning.wh.title") }}
    </div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="address.to"
            :headerText="$t('models.family_address.to')"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="offset"
            :headerText="$t('models.market_wh.offset')"
            type="number"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="address.address"
            :headerText="$t('models.family_address.address')"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="address.city"
            :headerText="$t('models.family_address.city')"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="address.postal_code.id"
            :headerText="$t('models.family_address.postal_code')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="code"
            :dataSource="CapData"
            foreignKeyField="id"
            editType="dropdownedit"
            :edit="postal_codeParams"
          ></e-column>
          <e-column
            field="address.country"
            :headerText="$t('models.family_address.country')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="name"
            :dataSource="CountryData"
            foreignKeyField="code"
            editType="dropdownedit"
            :edit="countryParams"
          ></e-column>
          <e-column
            field="notifyto"
            :headerText="$t('models.market_wh.notifyto')"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="info"
            :headerText="$t('models.market_wh.info')"
            clipMode="EllipsisWithTooltip"
            :disableHtmlEncode="false"
          ></e-column>
          <!-- <e-column
            field="order_close"
            :headerText="$t('models.market_plan.order_close')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="scheduleWeek"
            foreignKeyField="value"
          ></e-column>
          <e-column
            field="order_close_time"
            :headerText="$t('models.market_plan.order_close_time')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams"
          ></e-column>
          <e-column
            field="producer_close"
            :headerText="$t('models.market_plan.producer_close')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="scheduleWeek"
            foreignKeyField="value"
          ></e-column>
          <e-column
            field="producer_close_time"
            :headerText="$t('models.market_plan.producer_close_time')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams"
          ></e-column>
          <e-column
            field="producer_delivery"
            :headerText="$t('models.market_plan.producer_delivery')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="scheduleWeek"
            foreignKeyField="value"
          ></e-column>
          <e-column
            field="producer_delivery_time"
            :headerText="$t('models.market_plan.producer_delivery_time')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams"
          ></e-column>
          <e-column
            field="order_delivery"
            :headerText="$t('models.market_plan.order_delivery')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="scheduleWeek"
            foreignKeyField="value"
          ></e-column>
          <e-column
            field="order_delivery_time"
            :headerText="$t('models.market_plan.order_delivery_time')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams"
          ></e-column> -->
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import { Query } from "@syncfusion/ej2-data";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import TB from "../../../common/TB";
let countryElem, stateElem, countryObj, stateObj;
export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_market_planningwh", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: ["info", "id"],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Normal",
        },
        toolbar: ["Add", "Edit", "Delete", "Update", "Cancel"],
        // reload_on_save:true,
      },
      countryParams: {
        create: () => {
          countryElem = document.createElement("input");
          return countryElem;
        },
        read: () => {
          return countryObj.value;
        },
        destroy: () => {
          countryObj.destroy();
        },
        write: (args) => {
          countryObj = new DropDownList({
            dataSource: GetDataManager("geo_country"),
            fields: { value: "code", text: "name" },
            query: new Query().where("status", "equal", 100),
            value: args.rowData.address.country,
            change: () => {
              stateObj.enabled = true;
              let tempQuery = new Query()
                .where("country_id", "equal", countryObj.value)
                .take(10);
              stateObj.query = tempQuery;
              stateObj.text = null;
              stateObj.dataBind();
            },
            placeholder: "Select a country",
            floatLabelType: "Never",
          });
          countryObj.appendTo(countryElem);
        },
      },
      // postal_codeParams: {
      //   params: {
      //     allowFiltering: true,
      //     dataSource: GetDataManager("geo_postalcode"),
      //     fields: { text: "code", value: "id" },
      //     query: new Query().where("country_id", "equal", "CH").take(10),
      //     actionComplete: () => false,
      //     // }
      //   },
      // },
      postal_codeParams: {
        create: () => {
          stateElem = document.createElement("input");
          return stateElem;
        },
        read: () => {
          return stateObj.value;
        },
        destroy: () => {
          stateObj.destroy();
        },
        write: (args) => {
          stateObj = new DropDownList({
            dataSource: GetDataManager("geo_postalcode"),
            fields: { value: "id", text: "code" },
            value: args.rowData.address.postal_code.id,
            enabled: args.rowData.address.postal_code.id > 0,
            query: new Query()
              .where("country_id", "equal", args.rowData.address.country)
              .take(10),
            placeholder: "Select a postal code",
            floatLabelType: "Never",
            allowFiltering: true,
            actionComplete: () => false,
          });
          stateObj.appendTo(stateElem);
        },
      },
      CountryData: GetDataManager("geo_country"),
      CapData: GetDataManager("geo_postalcode"),
      // scheduleWeek: new DataManager([
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=MO", label: "Lunedì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=TU", label: "Martedì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=WE", label: "Mercoledì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=TH", label: "Giovedì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=FR", label: "Venerdì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=SA", label: "Sabato" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=SU", label: "Domenica" },
      // ]),
      // dpParams: {
      //   create: function () {
      //     elem = document.createElement("input");
      //     return elem;
      //   },
      //   read: () => {
      //     return datePickerObj.value.getHours()+":"+datePickerObj.value.getMinutes();
      //   },
      //   destroy: () => {
      //     datePickerObj.destroy();
      //   },
      //   write: (args) => {
      //     datePickerObj = new TimePicker({
      //       value: new Date("1970-01-01 "+args.rowData[args.column.field]),
      //       floatLabelType: "Never",
      //     });
      //     datePickerObj.appendTo(elem);
      //   },
      // },
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>
