<template>
  <div>
    <div class="card-title mb-3">
      {{ $t("role.planning.truck.title") }}
    </div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="name"
            headerText="Nome"
            clipMode="EllipsisWithTooltip"
          ></e-column>
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import TB from "../../../common/TB";
export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_market_planningtruck", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: [
            // "user.first_name",
            "name",
            "id",
          ],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: false,
          mode: "Normal",
        },
        toolbar: ["Add", "Edit", "Delete", "Update", "Cancel"],
        reload_on_save: true,
      },
      // permissionsData: new DataManager([
      //   { value: 0, label: "nessuno" },
      //   { value: 1, label: "lettura" },
      //   { value: 2, label: "lettura e scrittura" },
      // ]),
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>
